import "@babel/polyfill";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as serviceWorker from "./registerServiceWorker";
import { Settings, Storage } from "@havi/common";
import "./index.scss";
import { CircularProgress } from "@material-ui/core";
import baseName from "./BaseName";
import AsyncComponent from "./AsyncComponent";

const rootEl = document.getElementById("root");

function startRoot() {
  const App = AsyncComponent(() => import("./App"));
  ReactDOM.render(<App />, rootEl);
}

async function index() {
  if (
    process.env.REACT_APP_CATEGORY_SPEND_LOCAL_STORAGE_USER &&
    window.location.origin.startsWith("http://localhost:")
  ) {
    Storage.User = JSON.parse(process.env.REACT_APP_CATEGORY_SPEND_LOCAL_STORAGE_USER);
  }

  await Settings.configure({
    basename: baseName,
    settingsUrl: "https://csa-profileapi-shared.azurewebsites.net/api/app-settings"
  });
  startRoot();
}

ReactDOM.render(
  <div
    style={{
      position: "absolute",
      width: "99vw",
      height: "99vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden"
    }}
  >
    <CircularProgress style={{ color: "#006cb6" }} size={70} thickness={2} />
  </div>,
  rootEl
);

index();

serviceWorker.unregister();
